import React from 'react'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import get from 'lodash/get'
import cx from 'classnames'

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Container from '../components/container'
import * as styles from './blog-post.module.css'

function BlogPostTemplate({ data, location }) {
  const { language } = useI18next()
  const post = get(data, 'contentfulBlogPost')
  const previous = get(data, 'previous')
  const next = get(data, 'next')

  const shareUrl = typeof window !== 'undefined' ? window.location.href : ''

  return (
    <Layout location={location} logoType="full">
      <Seo
        title={post.title}
        description={post.description.childMarkdownRemark.excerpt}
        image={`http:${post?.heroImage?.resize?.src}`}
      />
      <Container>
        <article
          className="pt-6"
          itemscope
          itemtype="http://schema.org/BlogPosting"
        >
          <div class="w-full mx-auto mb-12 text-left lg:w-2/3">
            {post.heroImage?.gatsbyImageData && (
              <GatsbyImage
                className="h-80 w-auto object-cover rounded-xl"
                alt={post.title}
                image={post.heroImage?.gatsbyImageData}
              />
            )}
            {/* <p class="mt-6 mb-2 text-xs font-semibold tracking-wider uppercase text-primary">
              Development
            </p> */}
            <h1
              class="mt-4 mb-3 text-3xl font-bold leading-tight text-gray-900 md:text-4xl"
              itemprop="headline"
              title={post.title}
            >
              {post.title}
            </h1>

            {post?.tags?.length > 0 && (
              <div className="flex flex-row gap-2 mt-4">
                {post.tags.map((tag) => (
                  <small
                    key={tag}
                    className="rounded-lg px-2 py-1 bg-slate-100 text-gray-500"
                  >
                    {tag}
                  </small>
                ))}
              </div>
            )}

            <p class="font-semibold text-gray-800 mt-2"> {post.author?.name}</p>
            <p className="text-gray-500 ">
              <time dateTime={post.rawDate}>{post.publishDate}</time> –{' '}
              {post.body?.childMarkdownRemark?.timeToRead} minute read
            </p>
            <div className="gap-2 mt-2">
              <FacebookShareButton
                url={shareUrl}
                className="opacity-80 hover:opacity-50 mr-3 scale-110 md:mr-2"
              >
                <svg
                  class="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clip-rule="evenodd"
                  />
                </svg>
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                className="opacity-80 hover:opacity-50 mr-3 scale-110 md:mr-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z" />
                </svg>
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareUrl}
                className="opacity-80 hover:opacity-50 mr-3 scale-110 md:mr-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z" />
                </svg>
              </LinkedinShareButton>
            </div>
          </div>
          <div className={cx(styles.article, 'w-full mx-auto prose lg:w-2/3')}>
            <div
              dangerouslySetInnerHTML={{
                __html: post.body?.childMarkdownRemark?.html,
              }}
            />
          </div>
        </article>

        {(previous || next) && (
          <nav className="mt-12 md:mt-16">
            <ul className="flex wrap justify-between ">
              {previous && (
                <li className="max-w-xs overflow-hidden">
                  <div>←</div>
                  <Link
                    className="hover:text-brand inline-block"
                    to={`/blog-post/${previous.slug}`}
                    language={language}
                    rel="prev"
                  >
                    {previous.title}
                  </Link>
                </li>
              )}
              {next && (
                <li className="max-w-xs overflow-hidden">
                  <div className="text-right">→</div>
                  <Link
                    className="hover:text-brand inline-block"
                    to={`/blog-post/${next.slug}`}
                    language={language}
                    rel="next"
                  >
                    {next.title}
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        )}
      </Container>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $language: String!
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
    $locale: String!
  ) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulBlogPost(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      node_locale
      slug
      title
      author {
        name
      }
      publishDate(formatString: "MMMM Do, YYYY")
      rawDate: publishDate
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      body {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
      description {
        childMarkdownRemark {
          excerpt
        }
      }
    }
    previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`
